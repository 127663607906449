<script>
	import { range } from 'd3-array'
	import { curveCardinalClosed, line } from 'd3-shape'
	import { getContext } from 'svelte'

	const { width, height } = getContext('LayerCake')

	const colors = [
		'#A0015E',
		'#FFE508',
		'#FFE508',
		'#FFE508',
		'#FFE508',
		'#C80025',
		'#C80025',
		'#FCB616',
		'#1EDD73',
		'#8EC840',
		'#06AFEA',
		'#06AFEA',
		'#06AFEA',
		'#4200A8'
	]

	const n = 8

	const data = range(200).map((d) => {
		const x = Math.random()

		return {
			x,
			y: Math.random(),
			r: Math.random(),
			h: Math.random(),
			c: colors[Math.floor(x * colors.length)],
			p: range(n).map((i) => {
				const radius = Math.random() * 8
				const radiusVariation = Math.random() * 4
				return [
					Math.cos((i / n) * Math.PI * 2) * (2 + radius + 0.2 * radius),
					Math.sin((i / n) * Math.PI * 2) * (2 + radius + 0.2 * radius)
				]
			})
		}
	})

	let scrollY
	$: dt = scrollY * 0.001 || 0

	$: l = line()
		.x((d) => d[0])
		.y((d) => d[1])
		.curve(curveCardinalClosed)
</script>

<g transform="translate({$width / 2}, {400})">
	{#each data as d, i}
		<g
			transform="translate({Math.cos(d.x * Math.PI * 2) *
				(150 + $width * (0 + dt * d.y))}, {Math.sin(d.x * Math.PI * 2) *
				(130 + $width * (0 + dt * d.y))})"
		>
			<path transform="rotate({dt * 2000 * d.x})" d={l(d.p)} fill={d.c} />
		</g>
	{/each}
</g>

<svelte:window bind:scrollY />
