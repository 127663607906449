<script>
	import StarIcon from './icons/StarIcon.svelte'
	import VideoPlayer from 'svelte-video-player'

	let activeIndex = 0
</script>

<div class="m-auto p-4">
	<div class="lg:w-2/3 mx-auto mb-8">
		<h2 class="md:text-5xl sm:text-4xl text-3xl font-bold mb-6">A unique learning experience</h2>
		<div>
			<p class="text-lg">
				You'll get a unique learning experience hardly ever seen in other courses. Besides video
				instructions, a big part of this course is a custom built interactive playground in which I
				have prepared specific situations for you to practice. You can interactively modify designs,
				manipulate data or explore basic statistics of a dataset, all with the click of a button.
				This way you can really focus on practicing the thinking process, and makes the course very
				accessible: no programming skills or prior data knowledge required! You practice in context,
				in situations that I have prepared for you. And instead of simple multiple choice questions,
				you can explore and find out yourself what the effects of your decisions are. Once you're
				done with an exercise I will show you what I would do, and why. It also offers you a guided
				thinking process where you interactively have to advance to the next step in your thinking.
			</p>
		</div>
	</div>

	<!-- <div class="lg:grid lg:grid-cols-[400px_1fr] rounded py-4"> -->
	<div class="flex lg:flex-row flex-col-reverse">
		<div class="grow text-base">
			<div
				class="mt-1 lg:mt-0 p-2 lg:p-4 lg:rounded-l-lg lg:rounded-r-none rounded-lg {activeIndex ===
				0
					? 'bg-white lg:border-r lg:border-r-slate-200'
					: 'hover:bg-slate-100'} cursor-pointer"
				on:click={() => (activeIndex = 0)}
			>
				<h4 class="font-bold">
					<StarIcon style="h-4" />
					Tweak the design
				</h4>
				<p class="ml-7">Practice your design skills in a near real-life situation</p>
			</div>
			<div
				class="mt-1 lg:mt-0 p-2 lg:p-4 lg:rounded-l-lg lg:rounded-r-none rounded-lg {activeIndex ===
				1
					? 'bg-white border-r lg:border-r-slate-200'
					: 'hover:bg-slate-100'} cursor-pointer"
				on:click={() => (activeIndex = 1)}
			>
				<h4 class="font-bold">
					<StarIcon style="h-4" />
					Manipulate data
				</h4>
				<p class="ml-7">Learn how to restructure and enrich data at the click of a button.</p>
			</div>
			<div
				class="mt-1 lg:mt-0 p-2 lg:p-4 lg:rounded-l-lg lg:rounded-r-none rounded-lg {activeIndex ===
				2
					? 'bg-white lg:border-r lg:border-r-slate-200'
					: 'hover:bg-slate-100'} cursor-pointer"
				on:click={() => (activeIndex = 2)}
			>
				<h4 class="font-bold">
					<StarIcon style="h-4" />
					Quick statistical analysis
				</h4>
				<p class="ml-7">Easily find out the key characteristics of a dataset.</p>
			</div>
			<div
				class="mt-1 lg:mt-0 p-2 lg:p-4 lg:rounded-l-lg lg:rounded-r-none rounded-lg {activeIndex ===
				3
					? 'bg-white lg:border-r lg:border-r-slate-200'
					: 'hover:bg-slate-100'} cursor-pointer"
				on:click={() => (activeIndex = 3)}
			>
				<h4 class="font-bold">
					<StarIcon style="h-4" />
					Adjust advanced visualizations
				</h4>
				<p class="ml-7">Modify even advanced visualizations through a guided thinking process.</p>
			</div>
		</div>
		<div class="p-2 lg:p-4 bg-white lg:rounded-r-lg lg:rounded-l-none rounded-lg w-full">
			<div class={activeIndex === 0 ? '' : 'hidden'}>
				<VideoPlayer
					source={['video/create.webm', 'video/create.mp4']}
					poster="video/poster_create.jpg"
					playerBgColor="#fff"
					color="#475569"
				/>
			</div>
			<div class={activeIndex === 1 ? '' : 'hidden'}>
				<VideoPlayer
					source={['video/data.webm', 'video/data.mp4']}
					poster="video/poster_data.jpg"
					playerBgColor="#fff"
					color="#475569"
				/>
			</div>
			<div class={activeIndex === 2 ? '' : 'hidden'}>
				<VideoPlayer
					source={['video/statistics.webm', 'video/statistics.mp4']}
					poster="video/poster_statistics.jpg"
					playerBgColor="#fff"
					color="#475569"
				/>
			</div>
			<div class={activeIndex === 3 ? '' : 'hidden'}>
				<VideoPlayer
					source={['video/advanced.webm', 'video/advanced.mp4']}
					poster="video/poster_advanced.jpg"
					playerBgColor="#fff"
					color="#475569"
				/>
			</div>
		</div>
	</div>
</div>
