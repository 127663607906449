<div class="border-b border-slate-200 pb-4 mb-4">
	<div class="font-bold text-4xl text-black mb-4">€ TBD,-</div>
	<button
		disabled
		class=" py-2 w-full my-2 bg-gray-300 rounded text-white text-xl cursor-pointer text-center font-bold"
		><span
			><svg
				xmlns="http://www.w3.org/2000/svg"
				class="h-6 w-6 mr-2 inline"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
				/>
			</svg></span
		>Buy now
	</button>
	<div class="animate-bounce w-full text-center my-2  text-black text-xl font-bold">
		Enrollment starts soon!
	</div>
</div>
