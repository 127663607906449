<script>
	import Email from './Email.svelte'
	import Facebook from './Facebook.svelte'
	import Twitter from './Twitter.svelte'
	import LinkedIn from './LinkedIn.svelte'

	export let url = 'https://creativedataviz.com/'
	export let title = 'The Art of Creative Dataviz Design'
	export let desc =
		'The Art of Creative Dataviz Design: A unique online course about the thinking process behind some of the most stunning data visualizations.'
</script>

<!-- inspired by https://github.com/pchynoweth/svelte-share-buttons-component -->
<div class="flex justify-between w-2/3 m-auto">
	<Twitter {url} text={title} {desc} />
	<Facebook {url} quote={title} {desc} />
	<LinkedIn {url} {desc} />
	<Email {url} subject={title} body={desc} />
</div>
