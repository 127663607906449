<script>
	import { fade } from 'svelte/transition'

	const photos = [
		'img/nielsen.jpg',
		'img/starmapper.jpg',
		'img/human-gene-expression.png',
		'img/trillions-of-trees.png'
	]

	let index = 0

	const next = () => {
		index = (index + 1) % photos.length
	}

	// setInterval(() => next(), 3000)
</script>

<div class="grid grid-cols-2 gap-2">
	<!-- {#each [photos[index]] as src (index)} -->
	{#each photos as src}
		<!-- <img class="absolute" transition:fade={{ duration: 800 }} {src} alt="" /> -->
		<img {src} alt="" />
	{/each}
</div>
