<script>
	import { LayerCake, Svg } from 'layercake'
	import BackgroundViz from './BackgroundViz.svelte'
	import Carousel from './Carousel.svelte'
	import Marquee from 'svelte-fast-marquee'
	import FAQ from './FAQ.svelte'
	import Content from './Content.svelte'
	import InfoPanel from './InfoPanel.svelte'
	import PlaygroundInfo from './PlaygroundInfo.svelte'
	import Buy from './Buy.svelte'
</script>

<video autoplay muted loop class="object-cover h-screen w-screen fixed">
	<source src="video/demoreel_.mp4" type="video/mp4" />
</video>
<!-- 
<div class="w-full absolute top-1/4 bg-gradient-to-r from-transparent via-white p-8">
	<img class="m-auto w-1/4" alt="course-title" src="img/logo_ACDD.svg" />
</div> -->

<div class="absolute top-2/3 m-auto w-full">
	<!-- <div class="fixed top-0 left-0 w-full h-full ">
		<svg width={1200} height={1200}>
			{#each [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] as i}
				<circle
					cx={`${i * 10}%`}
					cy={`${i * 10}%`}
					r={`${i * 10}%`}
					fill="transparent"
					stroke="rgba(255, 255, 255, 0.3)"
					stroke-width={`${i * 2}px`}
				/>
			{/each}
			<circle r={100} cx={200} cy={200} fill="none" stroke="white" stroke-width={20} />
		</svg>
	</div> -->

	<div class="w-full bg-slate-900/80">
		<div class="container m-auto p-4 relative bg-gradient-to-b from-slate-100 to-slate-300">
			<div class="grid grid-cols-1 relative">
				<!-- <div class="sticky top-0 left-0 row-start-1 col-start-1 w-full h-full z-100">
		<LayerCake>
			<Svg>
				<BackgroundViz />
			</Svg>
		</LayerCake>
	</div> -->

				<div class="row-start-1 col-start-1 z-0">
					<!-- <img
			class="m-auto"
			alt="course-title"
			src="img/the-art-of-creative-dataviz-design.png"
			width="70%"
		/> -->
					<img class="m-auto w-2/4 pt-8" alt="course-title" src="img/logo_ACDD.svg" />

					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="h-6 w-6 m-auto animate-bounce my-8"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						stroke-width="2"
					>
						<path stroke-linecap="round" stroke-linejoin="round" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
					</svg>

					<div class="m-auto py-12 lg:py-20 px-4 w-full max-w-md md:max-w-lg lg:max-w-2xl">
						<p class="text-xl md:text-2xl lg:text-4xl text-center text-slate-900 leading-snug">
							A unique <span class="underline">online course</span> about the
							<span class="italic font-bold">thinking process</span> behind some of the most stunning
							data visualizations.
						</p>
					</div>

					<!-- TODO: VIDEO-->
					<!-- <div class="w-full m-auto mb-10 md:mb-20">
			<img src="img/test-capture1.png" alt="test" />
		</div> -->

					<!-- <div class="m-auto w-full p-4 bg-white rounded"> -->
					<!-- <video controls>
				<source src="video/C0009.MP4" type="video/mp4" />
				<track kind="captions" />
			</video> -->

					<!-- <div class="aspect-w-16 aspect-h-9"> -->
					<!-- <iframe
					src="https://www.youtube.com/v/eS-XhmX390I&hl=en&fs=1"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				/> -->
					<!-- <object title="promo" class="w-full m-auto mb-10 md:mb-20">
					<param name="movie" value="https://www.youtube.com/v/aoDaYwFZ2rI&hl=en&fs=1" />
					<param name="allowFullScreen" value="true" />
					<embed
						src="https://www.youtube.com/v/aoDaYwFZ2rI&hl=en&fs=1"
						type="application/x-shockwave-flash"
						allowfullscreen={true}
						width="100%"
						height="100%"
					/>
				</object> -->
					<!-- <div style="padding:56.25% 0 0 0;position:relative;"> -->
					<!-- <iframe
					src="https://player.vimeo.com/video/697169622?h=51a74e86d3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
					frameborder="0"
					allow="autoplay; fullscreen; picture-in-picture"
					allowfullscreen
					style="position:absolute;top:0;left:0;width:100%;height:100%;"
					title="The Art of Creative Dataviz Design - promo"
				/> -->
					<!-- </div> -->
					<!-- <script src="https://player.vimeo.com/api/player.js"></script> -->
					<!-- </div> -->
					<!-- </div> -->

					<div class="w-full bg-slate-200 pb-2 rounded">
						<h2 class="w-full font-bold text-center rounded-t bg-sky-500 text-white p-2 text-xl">
							<span class="animate-pulse">Please fill out this form below</span>
						</h2>
						<iframe
							class="m-auto"
							src="https://docs.google.com/forms/d/e/1FAIpQLScHSqT08jiInz0DGfnIm7uVQvnE3v4XDLJP1HC1zPbGoyKPUA/viewform?embedded=true"
							width="640"
							height="1085"
							frameborder="0"
							marginheight="0"
							marginwidth="0">Loading…</iframe
						>
					</div>
					<!-- <section class="lg:grid lg:grid-cols-[1fr_400px] lg:py-20 lg:gap-10 mb-40"> -->
					<section
						class="flex flex-col-reverse lg:grid lg:grid-cols-[1fr_400px] lg:py-20 lg:gap-10 mb-40"
					>
						<Content />
						<InfoPanel />
					</section>

					<section class="mb-40">
						<PlaygroundInfo />
					</section>

					<section class="grid xl:grid-cols-2 xl:gap-8 p-6 md:p-10 mb-20">
						<div class="p-2">
							<h2 class="md:text-5xl sm:text-4xl text-3xl font-bold my-4">What will you learn?</h2>
							<p class="text-lg">
								"If you would learn everything about how a piano works, it will not teach you much
								about making music". This course is about making music, figuratively speaking of
								course. There are two main motivations for this course: First, I believe that there
								are already many resources available to learn tools and technologies, as well as the
								theory of data visualization. But ultimately, if you create a data visualization
								yourself, you still have to make a lot of decisions. But how do you do that? How do
								you decide what a color should be? Where do you place something on screen or on
								paper? How big should something be? And second, many people are stuck thinking in
								"just bar charts, line charts and pie charts". And I think there's a great
								opportunity to learn how to think more creatively about how to represent data
								visually.
							</p>
						</div>

						<div class="p-4">
							<h2 class="md:text-5xl sm:text-4xl text-3xl font-bold my-4">What do you need?</h2>
							<p class="text-lg">
								Not so much actually! This course is designed to be accessible to many people, and
								of all skill levels. No programming skills are required. It's also perfectly fine if
								you haven't worked with data yet. This course will take you step by step through the
								thinking process of designing creative visualization, how to come up with original
								ideas, and how to critically evaluate your own design. And during the course you can
								play around with the design and data interactively, just with the click of a button.
								It doesn't get any easier than that! This course is designed to work on
								desktop/laptop screens, not on small mobile devices, simply because you will
								practice in the interactive playground.
							</p>
						</div>
					</section>

					<section
						class="m-auto mb-40 rounded-xl bg-white/20 shadow-xl shadow-slate-400/20 border-t border-t-white/30"
					>
						<h2
							class="border-b border-b-slate-300/60 w-full text-center mb-4 rounded-t-xl py-4 md:py-6 bg-white/50 lg:mb-4 md:text-5xl sm:text-4xl text-3xl font-bold"
						>
							Your instructor
						</h2>
						<div class="grid grid-cols-1 lg:grid-cols-2 px-10 lg:px-20">
							<div class="h-full flex content-end flex-wrap lg:pb-20">
								<img class="m-auto max-h-[650px]" alt="Jan Willem Tulp" src="img/me_cropped.png" />
							</div>
							<div class="mt-10 lg:mt-0 mb-20">
								<p class="text-lg mt-4">
									Jan Willem Tulp is an award winning Data Experience Designer from the Netherlands
									with over 10 years of experience of creating custom data visualizations. Over the
									years Jan Willem has created many custom creative data visualizations. He has
									worked with fantastic organizations, such as:
									<span class="block my-3">
										<Marquee speed={20}>
											<img
												src="img/sciam.svg"
												class="h-5 inline mr-2 mb-4 px-2"
												alt="Scientific American"
											/>
											<img
												src="img/schiphol.svg"
												class="h-5 inline mr-2 mb-4 px-2"
												alt="Amsterdam Schiphol Airport"
											/><img
												src="img/nat_geo.svg"
												class="h-5 inline mr-2 mb-4 px-2"
												alt="National Geograpic"
											/>
											<img
												src="img/esa.svg"
												class="h-5 inline mr-2 mb-4 px-2"
												alt="European Space Agency"
											/>
											<img
												src="img/worldbank.svg"
												class="h-5 inline mr-2 mb-4 px-2"
												alt="World Bank"
											/>
											<img
												src="img/heineken.svg"
												class="h-5 inline mr-2 mb-4 px-2"
												alt="Heineken"
											/>
											<img src="img/google.svg" class="h-5 inline mr-2 mb-4 px-2" alt="Google" />
											<img src="img/philips.svg" class="h-5 inline mr-2 mb-4 px-2" alt="Philips" />
										</Marquee>
									</span>
								</p>
								<p class="mb-2 text-lg">
									His work has been shown in many books, magazines and exhibitions. Jan Willem
									speaks regularly at (inter)national conferences.
								</p>
								<p class="text-lg">
									Check out <a
										href="https://tulpinteractive.com"
										target="_blank"
										title="TULP interactive"
										class="text-orange-600 border-b border-orange-600 border-dashed hover:border-solid font-bold"
										>my portfolio <svg
											xmlns="http://www.w3.org/2000/svg"
											class="h-4 w-4 inline"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											stroke-width="2"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
											/>
										</svg></a
									> to see some of my work!
								</p>
								<div class="mt-2">
									<Carousel />
								</div>
							</div>
						</div>
					</section>

					<FAQ />
				</div>
			</div>
		</div>
		<footer class="container p-2 bg-slate-400 text-slate-900 m-auto">
			<div class="p-4 text-center">
				<span>&copy; {new Date().getFullYear()}</span><img
					class="ml-4 inline h-14"
					alt="TULP interactive"
					src="img/logo_full_transparent_bg.png"
				/>
			</div>
		</footer>
	</div>
</div>
