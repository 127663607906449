<!-- Begin Mailchimp Signup Form -->
<div id="mc_embed_signup">
	<form
		action="https://tulpinteractive.us3.list-manage.com/subscribe/post?u=928cde1be2557a77cd0a15115&amp;id=f1f805e379"
		method="post"
		id="mc-embedded-subscribe-form"
		name="mc-embedded-subscribe-form"
		class="validate"
		target="_blank"
		novalidate
	>
		<div id="mc_embed_signup_scroll" class="block p-6 rounded bg-slate-100 w-full ">
			<h2 class="font-bold">Get notified about course updates</h2>
			<div class="mc-field-group mb-6">
				<label for="mce-EMAIL" class="inline-block mb-2 text-slate-700"
					>Email Address <span class="asterisk text-red-500 rounded">*</span>
				</label>
				<input
					type="email"
					value=""
					name="EMAIL"
					class="required email block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-600 focus:outline-none"
					id="mce-EMAIL"
					placeholder="Enter email"
				/>
			</div>
			<div class="mc-field-group mb-6">
				<label for="mce-FNAME" class="inline-block mb-2 text-slate-700"
					>First Name <span class="asterisk text-red-500 rounded">*</span>
				</label>
				<input
					type="text"
					value=""
					name="FNAME"
					class="required block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-600 focus:outline-none"
					id="mce-FNAME"
					placeholder="Enter first name"
				/>
			</div>
			<div class="indicates-required mb-1 text-xs">
				<span class="asterisk text-red-500 rounded">*</span> indicates required
			</div>
			<div hidden="true"><input type="hidden" name="tags" value="3409801" /></div>
			<div id="mce-responses" class="clear foot">
				<div class="response" id="mce-error-response" style="display:none" />
				<div class="response" id="mce-success-response" style="display:none" />
			</div>
			<!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
			<div style="position: absolute; left: -5000px;" aria-hidden="true">
				<input type="text" name="b_928cde1be2557a77cd0a15115_f1f805e379" tabindex="-1" value="" />
			</div>
			<div class="optionalParent">
				<div class="clear foot">
					<input
						type="submit"
						value="Subscribe"
						name="subscribe"
						id="mc-embedded-subscribe"
						class="button py-2 px-6 my-2 bg-red-500 rounded text-white text-xl cursor-pointer hover:bg-red-600 drop-shadow-xl w-full"
					/>
					<p class="brandingLogo mt-4">
						<a href="http://eepurl.com/hWjp8z" title="Mailchimp - email marketing made easy and fun"
							><img
								alt="MailChimp"
								class="opacity-40 m-auto"
								width="30%"
								src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"
							/></a
						>
					</p>
				</div>
			</div>
		</div>
	</form>
</div>

<!--End mc_embed_signup-->
