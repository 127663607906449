<script>
	export let href
	export let ariaLabel = ''
	export let style

	let classes = ''
</script>

<a {href} target="_blank" rel="noopener" aria-label={ariaLabel}>
	<div class=" w-10 h-10 rounded-full inline-block {style}">
		<!-- <div aria-hidden="true" class="ssbc-button__icon"> -->
		<div aria-hidden="true" class="fill-white ml-2 mt-2">
			<slot />
		</div>
	</div>
</a>
