<script>
	import ShareButton from './ShareButton.svelte'

	export let quote
	export let url
	export let ariaLabel = 'Share on Facebook'

	let href

	$: href = encodeURI(`https://facebook.com/sharer/sharer.php?u=${url}&quote=${quote}`)
</script>

<ShareButton {ariaLabel} {href} style="bg-[#3b5998] hover:bg-[#2d4373] active:bg-[#2d4373]">
	<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 24 24">
		<path
			d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"
		/>
	</svg>
</ShareButton>
