<script>
	import Collapsible from './Collapsible.svelte'
</script>

<div class="py-20 lg:w-2/3 m-auto">
	<h2 class="md:text-5xl sm:text-4xl text-3xl font-bold my-4">Frequently Asked Questions</h2>
	<div class="text-lg">
		<Collapsible>
			<svelte:fragment slot="toggle">When will the course be available?</svelte:fragment>
			<svelte:fragment slot="content"
				>I don't have an exact date yet. The decision to turn this into a custom built course rather
				than using existing course platforms means it's a bit more work than initially anticipated.
				That being said, I have made big progress both on the site, content, interactive playground
				and more. I am aiming to release the course sometime in 2022.
			</svelte:fragment>
		</Collapsible>
		<Collapsible>
			<svelte:fragment slot="toggle">Why does this course not run on mobile?</svelte:fragment>
			<svelte:fragment slot="content"
				>A big part of this course relies on an interactive playground that you will use to practice
				playing around with visualization designs. This is a highly interactive tool and it would
				not work well on very small screens.
			</svelte:fragment>
		</Collapsible>
		<!-- <Collapsible>
			<svelte:fragment slot="toggle">I have a payment issues. Help!?</svelte:fragment>
			<svelte:fragment slot="content"
				>Drop me a line at <a
					href="mailto:janwillem@tulpinteractive.com"
					title="janwillem@tulpinteractive.com"
					target="_blank"
					class="border-b border-slate-600 border-dotted hover:border-solid text-slate-900"
					>janwillem@tulpinterctive.com</a
				> and I'll look into it.</svelte:fragment
			>
		</Collapsible> -->
		<Collapsible>
			<svelte:fragment slot="toggle">Do I need to have technical experience?</svelte:fragment>
			<svelte:fragment slot="content"
				>No. The interactive playground lets you try out things, even somewhat advanced things,
				without any technical knowledge.</svelte:fragment
			>
		</Collapsible>
		<Collapsible>
			<svelte:fragment slot="toggle">What software do I need?</svelte:fragment>
			<svelte:fragment slot="content"
				>This is an online course that runs in the browser. So, all you need is a computer with an
				internet connection. No other software is needed.</svelte:fragment
			>
		</Collapsible>
		<Collapsible>
			<svelte:fragment slot="toggle"
				>Do I get a certificate at the end of the course?</svelte:fragment
			>
			<svelte:fragment slot="content">No.</svelte:fragment>
		</Collapsible>
		<Collapsible>
			<svelte:fragment slot="toggle"
				>Is this course also available as an in-company training?</svelte:fragment
			>
			<svelte:fragment slot="content"
				>Yes! Drop me a line at <a
					href="mailto:janwillem@tulpinteractive.com"
					title="janwillem@tulpinteractive.com"
					target="_blank"
					class="border-b border-slate-600 border-dashed hover:border-solid text-slate-900"
					>janwillem@tulpinterctive.com</a
				> and we'll discuss the possibilities.</svelte:fragment
			>
		</Collapsible>
		<Collapsible>
			<svelte:fragment slot="toggle"
				>Can I buy the course for my all my employees at once?</svelte:fragment
			>
			<svelte:fragment slot="content"
				>At the moment no. Each person needs to register and pay individually.</svelte:fragment
			>
		</Collapsible>
		<Collapsible>
			<svelte:fragment slot="toggle">When does the course start?</svelte:fragment>
			<svelte:fragment slot="content"
				>The course is 100% online and self-paced, so the course starts and finish whenever you
				want. You can do this course at your own pace.
			</svelte:fragment>
		</Collapsible>
		<Collapsible>
			<svelte:fragment slot="toggle"
				>Can I skip lessons or go back to lessons for review?</svelte:fragment
			>
			<svelte:fragment slot="content"
				>Yes! You can go back and forth between the lessons to review what interests you most and
				skip what you already know. But do note that the course has been set up with a logical
				sequence in mind and later sections may refer to earlier concepts.</svelte:fragment
			>
		</Collapsible>
		<Collapsible>
			<svelte:fragment slot="toggle">What is this course not?</svelte:fragment>
			<svelte:fragment slot="content">
				In this course you will not learn programming. You will also not learn how to use specific
				tools. Nor will this course teach you advanced data analysis or data processing skills. The
				main focus of this course is help you make better decisions, come up with creative ideas and
				solutions to show data. The interactive playground helps you focus on these decisions and
				ideas without the need to have programming and tool skills or knowledge about how to process
				data.
			</svelte:fragment>
		</Collapsible>
		<Collapsible>
			<svelte:fragment slot="toggle"
				>Will I be an expert dataviz designer after this course?</svelte:fragment
			>
			<svelte:fragment slot="content">
				Probably not, unless you already are :) However, this course will provide you with the
				thinking that is required to come up with creative data visualization ideas and solutions.
				To become an expert you need to practice. Lots of practice. And that simply takes time. This
				course however will fast-track your learning and help you become an expert faster!
			</svelte:fragment>
		</Collapsible>
		<Collapsible>
			<svelte:fragment slot="toggle">Why is this course custom built?</svelte:fragment>
			<svelte:fragment slot="content">
				Learning to design creative data visualizations is a skill that you need to practice. The
				best way to practice this skill is to practice in context, a situation as close as possible
				to the real-world situation. Most online course platforms are limited in the material you
				can offer: mostly video, multiple choice questions and PDF downloads. I want this course to
				be as effective as possible and to offer you an online learning experience you would not be
				able to get anywhere else. That's why I created the interactive playground, which allows me
				to prepare specific situations for you to practice, interactively, and making decisions in
				real-time. All without having to know a tool, how to write code or knowledge about data
				processing or statistics. This way I can tailor the context exactly for you, and you can
				practice with the real thing. The best possible learning experience, you cannot find
				anywhere else!
			</svelte:fragment>
		</Collapsible>
	</div>
</div>
