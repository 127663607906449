<script>
	import SocialShare from './social/SocialShare.svelte'
	import Buy from './Buy.svelte'
	import MailChimp from './MailChimp.svelte'
</script>

<div>
	<div class="bg-white p-10 rounded-lg drop-shadow-lg max-w-lg m-auto">
		<Buy />
		<div class="xl:grid-cols-none grid grid-cols-1">
			<div class="m-auto">
				<div>
					<div class="mb-0 small-caps text-lg">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-4 w-4 mr-1 inline"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							stroke-width="2"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
							/>
						</svg>Language: <span class="font-bold">English</span>
					</div>
					<div class="mb-0 small-caps text-lg">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-4 w-4 mr-1 inline"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							stroke-width="2"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
							/>
						</svg>Access: <span class="font-bold">Unlimited</span>
					</div>
					<div class="mb-0 small-caps text-lg">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-4 w-4 mr-1 inline"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							stroke-width="2"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
							/>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg>Type: <span class="font-bold">Online and self-paced</span>
					</div>
					<div class="mb-0 small-caps text-lg">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-4 w-4 mr-1 inline"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							stroke-width="2"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
							/>
						</svg>Type of exercises: <span class="font-bold">Interactive</span>
					</div>
				</div>
				<div>
					<div class="mb-0 small-caps text-lg">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-4 w-4 mr-1 inline"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							stroke-width="2"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
							/>
						</svg>Hours of video content: <span class="font-bold">tbd</span>
					</div>
					<div class="mb-0 small-caps text-lg">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-4 w-4 mr-1 inline"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							stroke-width="2"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
							/>
						</svg>Number of resources: <span class="font-bold">tbd</span>
					</div>
					<div class="mb-0 small-caps text-lg">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-4 w-4 mr-1 inline"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							stroke-width="2"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
							/>
						</svg>Number of lessons: <span class="font-bold">tbd</span>
					</div>
					<div class="mb-0 small-caps text-lg">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-4 w-4 mr-1 inline"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							stroke-width="2"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
							/>
						</svg>Level: <span class="font-bold">any</span>
					</div>
					<div class="mb-0 small-caps text-lg">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-4 w-4 mr-1 inline"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							stroke-width="2"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
							/>
						</svg>Device: <span class="font-bold">desktop / laptop / tablet</span>
					</div>
				</div>
			</div>
			<!-- <div class="flex justify-center py-8">
				<MailChimp />
			</div> -->
		</div>
		<div class="mt-8">
			<SocialShare />
		</div>
		<div class="mt-8">
			<MailChimp />
		</div>
	</div>
</div>
