<script>
	import { slide } from 'svelte/transition'

	let collapsed = true
</script>

<div class="mb-0 border-b-transparent border-b-2">
	<div
		on:click={() => (collapsed = !collapsed)}
		class="cursor-pointer p-2  {!collapsed
			? 'bg-slate-500/10 text-slate-700 font-bold hover:bg-slate-700/10'
			: 'hover:bg-slate-500/10'}"
	>
		<div class="pl-8 inline-block -indent-8">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="h-6 w-6 inline"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="1"
			>
				<path
					class="transition cubic-out origin-center {!collapsed
						? 'rotate-90'
						: '-rotate-90'} duration-[400ms]"
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M9 5l7 7-7 7"
				/>
			</svg>
			<slot name="toggle" />
		</div>
	</div>
	{#if !collapsed}
		<div transition:slide class="pt-2 pb-6 pl-10 px-4 bg-slate-400/10">
			<slot name="content" />
		</div>
	{/if}
</div>
