<script>
	import AcademicCapIcon from './icons/AcademicCapIcon.svelte'
	import BadgeCheckIcon from './icons/BadgeCheckIcon.svelte'
</script>

<div class="p-4">
	<h2 class="text-5xl font-bold my-4">Content</h2>
	<div
		class="tracking-wide text-red-800 bg-red-200 text-center rounded-full text-md py-1 px-4 my-8"
	>
		<span class="ml-1 font-bold">NOTE:</span> Content is still subject to change... somewhat 🙃
	</div>
	<div class="grid grid-rows-1 gap-5 m-auto py-4 text-lg">
		<div class="grid grid-cols-none xl:grid-cols-[250px_1fr] border-b border-slate-400 py-4">
			<div class="font-bold text-slate-900">
				<span
					class="relative w-8 h-8 bg-slate-300 rounded-full inline-flex justify-center items-center text-center p-4 mr-4"
					>1</span
				><span class="text-2xl font-bold">Welcome</span>
			</div>
			<div>
				<ol class="lg:pl-10 pt-6 pl-0 sm:pl-10 xl:pt-0">
					<li class="text-slate-900 mb-4">
						<div class="grid grid-cols-[24px_1fr]">
							<div>
								<AcademicCapIcon style="h-4 -mt-1" />
							</div>
							<span class="ml-1 font-bold text-slate-900">About this course</span>
						</div>
						<ol class="pl-10 text-slate-700">
							<li>
								<BadgeCheckIcon style="h-4 inline" />Hello!
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Structure of this course
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Educational background of this course
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Interactive playground
							</li>
						</ol>
						<!-- <p class="italic text-slate-500 ">
										In which we discuss how this course is structured, the educational foundation of
										the course, and the <em class="text-slate-800">interactive Playground</em> that you
										will use during this course.
									</p> -->
					</li>
				</ol>
			</div>
		</div>
		<div class="grid grid-cols-none xl:grid-cols-[250px_1fr] border-b border-slate-400 py-4">
			<div class="font-bold text-slate-900">
				<span
					class="relative w-8 h-8 bg-slate-300 rounded-full inline-flex justify-center items-center text-center p-4 mr-4 "
					>2</span
				><span class="text-2xl">Setting the stage</span>
			</div>
			<div>
				<ol class="lg:pl-10 pt-6 pl-0 sm:pl-10 xl:pt-0">
					<li class="text-slate-900 mb-4">
						<div class="grid grid-cols-[24px_1fr]">
							<div>
								<AcademicCapIcon style="h-4 -mt-1" />
							</div>
							<span class="ml-1 font-bold">2 Skills</span>
						</div>
						<ol class="pl-10 text-slate-700">
							<li>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4 inline stroke-orange-500"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
									/>
								</svg>
								Creation
							</li>
							<li>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4 stroke-purple-600 inline"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
								</svg>
								Evaluation
							</li>
						</ol>
					</li>
					<li class="text-slate-900 mb-4">
						<div class="grid grid-cols-[24px_1fr]">
							<div>
								<AcademicCapIcon style="h-4 -mt-1" />
							</div>
							<span class="ml-1 font-bold">4 Objectives</span>
						</div>
						<ol class="pl-10 text-slate-700">
							<li>
								<!-- <BadgeCheckIcon style="h-4 inline" /> -->
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4 inline stroke-red-600"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
									/>
								</svg>
								Novelty
							</li>
							<li>
								<!-- <BadgeCheckIcon style="h-4 inline" /> -->
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4 inline stroke-sky-500"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
									/>
								</svg>
								Aesthetic appeal
							</li>
							<li>
								<!-- <BadgeCheckIcon style="h-4 inline" /> -->
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4 inline stroke-amber-400"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
									/>
								</svg>
								Tailoring and Personlization
							</li>
							<li>
								<!-- <BadgeCheckIcon style="h-4 inline" /> -->
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4 inline stroke-emerald-600"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
									/>
								</svg>
								Challenge and Game-play
							</li>
						</ol>
					</li>
					<li class="text-slate-900 mb-4">
						<div class="grid grid-cols-[24px_1fr]">
							<div>
								<AcademicCapIcon style="h-4 -mt-1" />
							</div>
							<span class="ml-1 font-bold ">Creative (thinking) techniques</span>
						</div>
						<ol class="pl-10 text-slate-700">
							<li>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4 inline stroke-pink-600"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
									/>
								</svg>
								An open mind, playing around and "what if"
							</li>
							<li>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4 inline stroke-pink-600"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
									/>
								</svg>
								Attribute listing
							</li>
							<li>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4 inline stroke-pink-600"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
									/>
								</svg>
								Coming up with associations
							</li>
							<li>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4 inline stroke-pink-600"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									stroke-width="2"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
									/>
								</svg>
								Generating questions and topics
							</li>
						</ol>
					</li>
				</ol>
			</div>
		</div>
		<div class="grid grid-cols-none xl:grid-cols-[250px_1fr] border-b border-slate-400 py-4">
			<div class="font-bold text-slate-900">
				<span
					class="relative w-8 h-8 bg-slate-300 rounded-full inline-flex justify-center items-center text-center p-4 mr-4"
					>3</span
				><span class="text-2xl font-bold">Foundation</span>
			</div>
			<div>
				<ol class="lg:pl-10 pt-6 pl-0 sm:pl-10 xl:pt-0">
					<li class="text-slate-900 mb-4">
						<div class="grid grid-cols-[24px_1fr]">
							<div>
								<AcademicCapIcon style="h-4 -mt-1" />
							</div>
							<span class="ml-1 font-bold"
								>How do you map data to visual elements and properties?</span
							>
						</div>
						<ol class="pl-10 text-slate-700">
							<li>
								<BadgeCheckIcon style="h-4 inline" />Mapping to Appearance
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Mapping to Position
							</li>
						</ol>
					</li>
					<li class="text-slate-900 mb-4">
						<div class="grid grid-cols-[24px_1fr]">
							<div>
								<AcademicCapIcon style="h-4 -mt-1" />
							</div>
							<span class="ml-1 font-bold ">How do you look at data creatively?</span>
						</div>
						<ol class="pl-10 text-slate-700">
							<li>
								<BadgeCheckIcon style="h-4 inline" />Creative data assessment and looking beyond the
								obvious
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Basic data analysis
							</li>
						</ol>
					</li>
					<li class="text-slate-900 mb-4">
						<div class="grid grid-cols-[24px_1fr]">
							<div>
								<AcademicCapIcon style="h-4 -mt-1" />
							</div>
							<span class="ml-1 font-bold "
								>What should you look for when evaluating your design?</span
							>
						</div>
						<ol class="pl-10 text-slate-700">
							<li>
								<BadgeCheckIcon style="h-4 inline" />Magnitude: size, scale and proportion
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Negative space
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Points, lines, areas and volumes
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Color
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Visual hierarchy
							</li>
						</ol>
					</li>
				</ol>
			</div>
		</div>
		<div class="grid grid-cols-none xl:grid-cols-[250px_1fr] border-b border-slate-400 py-4">
			<div class="font-bold text-slate-900">
				<span
					class="relative w-8 h-8 bg-slate-300 rounded-full inline-flex justify-center items-center text-center p-4 mr-4"
					>4</span
				><span class="text-2xl font-bold">Topics</span>
			</div>
			<div>
				<ol class="lg:pl-10 pt-6 pl-0 sm:pl-10 xl:pt-0">
					<li class="text-slate-900 mb-4">
						<div class="grid grid-cols-[24px_1fr]">
							<div>
								<AcademicCapIcon style="h-4 -mt-1" />
							</div>
							<span class="ml-1 font-bold ">Tailoring the content</span>
						</div>
						<ol class="pl-10 text-slate-700">
							<li>
								<BadgeCheckIcon style="h-4 inline" />Framing
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Titles, annotations and labels
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Level of detail
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Creating unique datasets
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Contrast
							</li>
						</ol>
					</li>
					<li class="text-slate-900 mb-4">
						<div class="grid grid-cols-[24px_1fr]">
							<div>
								<AcademicCapIcon style="h-4 -mt-1" />
							</div>
							<span class="ml-1 font-bold ">Tailoring the view</span>
						</div>
						<ol class="pl-10 text-slate-700">
							<li>
								<BadgeCheckIcon style="h-4 inline" />Showing emotion
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Showing (missing) silent data
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Showing uncertainty
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Using metaphors
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Double encoding
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Using the background
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Making things stand out
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Making things belong to each other
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Animations and transitions
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Interpretive mapping
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Design hacks
							</li>
						</ol>
					</li>
					<li class="text-slate-900 mb-4">
						<div class="grid grid-cols-[24px_1fr]">
							<div>
								<AcademicCapIcon style="h-4 -mt-1" />
							</div>
							<span class="ml-1 font-bold ">Structure</span>
						</div>
						<ol class="pl-10 text-slate-700">
							<li>
								<BadgeCheckIcon style="h-4 inline" />Martini glass structure
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Direct experience
							</li>
						</ol>
					</li>
					<li class="text-slate-900 mb-4">
						<div class="grid grid-cols-[24px_1fr]">
							<div>
								<AcademicCapIcon style="h-4 -mt-1" />
							</div>
							<span class="ml-1 font-bold">Interactivity</span>
						</div>
						<ol class="pl-10 text-slate-700">
							<li>
								<BadgeCheckIcon style="h-4 inline" />Direct manipulation
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Enriched user controls
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Linked views
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Hook framework
							</li>
						</ol>
					</li>
				</ol>
			</div>
		</div>
		<div class="grid grid-cols-none xl:grid-cols-[250px_1fr]">
			<div class="font-bold text-slate-900">
				<span
					class="relative w-8 h-8 bg-slate-300 rounded-full inline-flex justify-center items-center text-center p-4 mr-4"
					>5</span
				><span class="text-2xl font-bold">Closing</span>
			</div>
			<div>
				<ol class="lg:pl-10 pt-6 pl-0 sm:pl-10 xl:pt-0">
					<li class="text-slate-900 mb-4">
						<div class="grid grid-cols-[24px_1fr]">
							<div>
								<AcademicCapIcon style="h-4 -mt-1" />
							</div>
							<span class="ml-1 font-bold ">Goodbye!</span>
						</div>
						<ol class="pl-10 text-slate-700">
							<li>
								<BadgeCheckIcon style="h-4 inline" />Summary
							</li>
							<li>
								<BadgeCheckIcon style="h-4 inline" />Goodbye!
							</li>
						</ol>
					</li>
				</ol>
			</div>
		</div>
	</div>
</div>
